import { NextPage } from "next"
import { RundooHead } from "components/Head"
import { SignIn } from "features/auth/SignIn"
import { SupportChatButton } from "components/SupportChatButton"

const AuthSignInPage: NextPage = () => {
  return (
    <>
      <RundooHead />
      <SignIn />
      <SupportChatButton className="fixed right-6 top-6 z-20" />
    </>
  )
}

export default AuthSignInPage
